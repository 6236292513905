<template>
    <div class="page-rooter-fff">
        <headeBar title="开卡记录" left-arrow @click-left="newAppBack"/>
        <div class="page-content">
            <van-tabs border swipeable v-model="active" title-active-color="#242831" title-inactive-color="#6C727A"
                      color="#242831" line-height="0.53vw" line-width="45.6vw">
                <van-tab title="使用中">
                    <div class="list" v-if="openRecordList.length">
                        <div @click="jumpCard(v)" class="item" v-for="(v, k) in openRecordList" :key="k">
                            <div>
                                <div class="name">{{ v.equityCardName }}</div>
                                <div class="use-tips">获得时间：{{ v.buyDate }}</div>
                                <div class="use-tips">来源：{{ v.equityCardSources }}</div>
                                <div v-if="v.status === 'WAIT_ACTIVE'" class="use-tips">生效期：按购买时间依次生效后计算有效期</div>
                                <div v-else-if="v.status === 'WAIT_RECEIVE'" class="use-tips">生效期：依次生效后计算有效期</div>
                                <div v-else-if="v.status === 'WAIT_OPEN'" class="use-tips">
                                    <template v-if="v.openDeadlineDate">生效期：下次进馆自动生效，在{{ v.openDeadlineDate }}前未进馆则自动{{ (v.isGivingCard == 0 && v.source === 'FRIEND_GIVING')? '生效': '退款'}}</template>
                                    <template v-else>生效期：下次进馆自动生效，永久有效</template>
                                </div>
                                <div v-else-if="v.status === 'AUTO_REFUND'" class="use-tips">生效期：未生效，未进馆自助消费自动退款</div>
                                <div v-else-if="v.validityStartDate && v.validityEndDate" class="use-tips">生效期：{{v.validityStartDate}} - {{ v.validityEndDate }}（已用{{ v.hasActiveDays || 0}}天）</div>
                                <div v-else class="use-tips">生效期：未生效</div>
                                <div class="use-tips" v-if="v.status === 'SUSPEND'">
                                    冻结说明：冻结日期为{{ v.suspendStartDate }}-{{ v.suspendEndDate }}（共{{ differenceDate(v.suspendStartDate, v.suspendEndDate) }}天），恢复后将以冻结的天数累加自动延长生效期
                                </div>
                                <div v-if="v.returnDays && v.returnDays>0" class="use-tips">退回天数：{{ v.returnDays }}天（该部分已退卡退款）</div>
                            </div>
                            <div class="status row-center-center">
                                <span v-if="v.status !== 'WAIT_RECEIVE'" v-html="v.status? status[v.status].htmlSellRecord: ''"></span>
                                <van-icon  v-if="['WAIT_ACTIVE', 'ACTIVE', 'WAIT_OPEN', 'WAIT_RECEIVE'].includes(v.status)" :color="status[v.status].color" name="arrow" />
                            </div>
                            <div  class="btn row-start-start" v-if="v.status === 'WAIT_RECEIVE'">
                                <p class="row-center-center gray">赠送中</p>
                            </div>
                            <div class="btn row-start-start" v-else-if="v.isCanActive || v.isCanGiving || ((v.status === 'WAIT_ACTIVE' || v.status === 'ACTIVE') && v.currentCanCancel === 1 && (!v.returnDays || v.returnDays <= 0))">
                                <p v-if="v.isCanActive" @click.stop="confirmActive(v)" class="row-center-center active">立即激活</p>
                                <p v-if="v.isCanGiving && v.type === 0" @click.stop="share(v)" class="row-center-center">赠送好友</p>
                                <div v-if="(v.status === 'WAIT_ACTIVE' || v.status === 'ACTIVE') && v.currentCanCancel === 1 && (!v.returnDays || v.returnDays <= 0)">
                                    <p @click.stop="cancelCard(v)" class="row-center-center">退卡</p>
                                    <span>{{ cancelDate(v.buyDate, v.cancelValidity) }}前可无理由</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="empty" v-else>
                        <img
                            src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/empty.png"
                            alt="">
                        <div>暂无数据</div>
                    </div>
                </van-tab>
                <van-tab title="已失效">
                    <div class="list" v-if="expiredRecordList.length">
                        <div @click="jumpCard(v)" class="item" v-for="(v, k) in expiredRecordList" :key="k">
                            <div>
                                <div class="name">{{ v.equityCardName }}</div>
                                <div class="use-tips">获得时间：{{ v.buyDate }}</div>
                                <div class="use-tips">来源：{{ v.equityCardSources }}</div>
                                <div v-if="v.status === 'WAIT_ACTIVE'" class="use-tips">生效期：按购买时间依次生效后计算有效期</div>
                                <div v-else-if="v.status === 'WAIT_RECEIVE'" class="use-tips">生效期：依次生效后计算有效期</div>
                                <div v-else-if="v.status === 'WAIT_OPEN'" class="use-tips">
                                    <template v-if="v.openDeadlineDate">生效期：下次进馆自动生效，在{{ v.openDeadlineDate }}前未进馆则自动{{ (v.isGivingCard == 0 && v.source === 'FRIEND_GIVING')? '生效': '退款'}}</template>
                                    <template v-else>生效期：下次进馆自动生效，永久有效</template>
                                </div>
                                <div v-else-if="v.status === 'AUTO_REFUND'" class="use-tips">生效期：未生效，未进馆自助消费自动退款</div>
                                <div v-else-if="v.validityStartDate && v.validityEndDate" class="use-tips">生效期：{{v.validityStartDate}} - {{ v.validityEndDate }}</div>
                                <div v-else class="use-tips">生效期：未生效</div>
                                <div class="use-tips" v-if="v.status === 'SUSPEND'">
                                    冻结说明：冻结日期为{{ v.suspendStartDate }}-{{ v.suspendEndDate }}（共{{ differenceDate(v.suspendStartDate, v.suspendEndDate) }}天），恢复后将以冻结的天数累加自动延长生效期
                                </div>
                                <div v-if="v.returnDays && v.returnDays>0" class="use-tips">退回天数：{{ v.returnDays }}天（该部分已退卡退款）</div>
                            </div>
                            <div class="status row-center-center">
                                <span v-html="v.status? status[v.status].htmlSellRecord: ''"></span>
                                <van-icon v-if="v.status === 'WAIT_ACTIVE' || v.status === 'ACTIVE' || v.status === 'WAIT_OPEN'" color="#9AA1A9" name="arrow" />
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <img
                            src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/empty.png"
                            alt="">
                        <div>暂无数据</div>
                    </div>
                </van-tab>
                <!--  20221024  先注释掉
                <van-tab title="自动续费">
                    <div class="list" v-if="renewalRecordList.length">
                        <div class="item-box" v-for="(v, k) in renewalRecordList" :key="k">
                            <div class="item-top">
                                <div>
                                    <div class="name">{{ v.equityCardName }}</div>
                                    <div class="use-tips">开卡时间：{{ v.openDate }}</div>
                                    <div class="use-tips">生效期：{{ v.validityStartDate }} - {{ v.validityEndDate }}</div>
                                </div>
                                <div class="status row-center-center">
                                    <span v-if="v.isAutoRenew === 1">自动续费中</span>
                                    <span style="color: #9AA1A9" v-else>已关闭自动续费</span>
                                </div>
                            </div>
                            <div class="item-bottom">
                                <div class="pay-msg row-between-center">
                                    <div class="row-center-center"><img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/alipay.png" alt="">支付宝</div>
                                    <p v-if="v.isAutoRenew === 1" @click.stop="openCycle(v)">关闭</p>
                                </div>
                                <div class="pay-tips">
                                    <div>下次续费金额：{{ v.purchasePrice }}元</div>
                                    <p>*每{{ v.useValidity }}天由超鹿运动扣费，扣费成功后，会员有效期增加{{ v.useValidity }}天*</p>
                                </div>
                                <div class="pay-explanation">
                                    <div>自动续费说明</div>
                                    <p>购买自动续费服务，将不可购买非订阅类型自助卡，可关闭自动续费后再进行购买</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="empty">
                        <img
                            src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/empty.png"
                            alt="">
                        <div>暂无数据</div>
                    </div>
                </van-tab>
                -->
            </van-tabs>
        </div>
        <van-popup v-model="show" :style="{backgroundColor: 'transparent'}">
            <div class="popup-box">
                <div class="popup-title">自动续费关闭确认</div>
                <div class="popup-content">关闭自动续费后我们将不继续自动扣费，自助会员卡到期后您将不再享受自助卡会员权益了哦，确认要关闭吗？</div>
                <div class="popup-btn row-start-start">
                    <div @click="show = false">继续享用权益</div>
                    <div @click="closeCard()">确认关闭</div>
                </div>
            </div>
        </van-popup>
        <van-popup round v-model="showRefund" position="bottom">
            <div class="refund-box">
                <div class="refund-title">请确认是否退卡</div>
                <div class="refund-content">{{refundInfo.instructions}}</div>
                <div class="refund-info">收回时长：{{ refundInfo.refundDay }} 天</div>
                <div class="refund-info">计算后退还金额：¥ {{ refundInfo.refundPrice }}</div>
                <div class="refund-btn row-between-center">
                    <div @click="showRefund = false">继续享用权益</div>
                    <div @click="confirmRefund(refundInfo)">确认退卡</div>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="showActivate" :style="{backgroundColor: 'transparent'}">
            <div class="popup-box">
                <div class="popup-title">是否立即激活？</div>
                <div class="popup-content">{{ cardType === 0 ? isCanGiving ? '' : '' : '' }}</div>
                <div class="popup-btn row-start-start">
                    <div @click="showActivate = false">我再想想</div>
                    <div @click="activate()">确认激活</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import userMixin from "@/mixin/userMixin";
import {newAppBack, getUserIdAndToken, initBack, getAppVersion} from "@/lib/appMethod";
import headeBar from '@/components/app/headBar';
import wx from "weixin-js-sdk";
import {status} from "@/views/month-card/const";
import {compareVersion, getFormatDate, getParam} from '@/lib/utils'
export default {
    data() {
        return {
            openRecordList: [],
            renewalRecordList: [],
            expiredRecordList: [],
            active: 0,
            cardType: 0,  // 卡类型 0自助卡  1团课
            isCanGiving: true, // 当前操作的自助卡是否可赠送
            show: false,
            showRefund: false,
            showActivate: false, // 激活
            status: status,
            refundInfo: {},
            selectItem: {},
            userMembershipCardId: '',
            itemUserEquityCardId: '' // 用户要激活的记录
        };
    },
    mixins: [userMixin],
    components: {headeBar},
    async created() {
        this.userMembershipCardId = getParam().userMembershipCardId
        initBack(); //劫持后退
        await this.$getUserId();
        this.getCardList()
        this.active = this.$route.query.active === '1'? 1: 0
    },
    mounted() {
    },
    methods: {
        // 获取卡列表
        getCardList() {
            this.$axios.post(`${this.baseURLApp}/user/equityCard/openAndRenewal/record/list`,{
                userId: this.userId,
                token: this.token,
                equityCardId: this.userMembershipCardId
            }).then(res => {
                this.renewalRecordList = res.data.renewalRecordList ?? []
                this.openRecordList = res.data.openRecordList ?? []
                this.expiredRecordList = res.data.expiredRecordList ?? []
            })
        },
        closeCard() {
            this.$toast.loading({
                forbidClick: true,
                loadingType:'spinner',
                duration: 0,
                message: '加载中...'
            });
            this.$axios.post(`${this.baseURLApp}/msCard/cyclePayUnSign`,{
                userId: this.userId,
                token: this.token,
                equityCardItemId: this.selectItem.equityCardItemId,
                userEquityCardId: this.selectItem.userEquityCardId,
            }).then(res => {
                this.$toast.clear()
                this.show = false
                this.$toast('关闭自动续费成功！')
                this.getCardList()
            }).catch(() => {
                this.$toast.clear()
            })
        },
        openCycle(v) {
            this.selectItem = v
            this.show = true
        },
        cancelDate(openDate, cancelValidity) {
            let day = cancelValidity || 0
            day = day - 1
            return getFormatDate((new Date(openDate.replace(/\./g, '/')).getTime()) + (day*24*60*60*1000), 'yyyy.MM.dd')
        },
        differenceDate(start, end){
            if(!start || !end){
                return 0
            }
            return (((new Date(end.replace(/\./g, '/')).getTime() - new Date(start.replace(/\./g, '/')).getTime())/24/60/60/1000)+1).toFixed(0)
        },
        cancelCard(v){
            this.$toast.loading({
                forbidClick: true,
                loadingType:'spinner',
                duration: 0,
                message: '加载中...'
            });
            this.$axios.post(`${this.baseURLApp}/equityCardRefund/app/refundInfo`,{
                userId: this.userId,
                cardId: v.userEquityCardId,
                isGift: false,
                userToken: this.token
            }).then(res => {
                this.$toast.clear()
                this.showRefund = true
               this.refundInfo = res.data
            }).catch(() =>{
                this.$toast.clear()
            })
        },
        confirmRefund(v) {
            this.$axios.post(`${this.baseURLApp}/equityCardRefund/app/refund`,{
                userId: this.userId,
                cardId: v.equityCardId,
                isGift: false,
                userToken: this.token
            }).then(res => {
                this.$toast(res.data)
                this.showRefund = false
                this.getCardList()
            })
        },
        jumpCard(v) {
            if(['WAIT_OPEN', 'WAIT_ACTIVE', 'ACTIVE', 'WAIT_RECEIVE'].includes(v.status)){
                if (this.appTypeStr === 'mini') {
                    wx.miniProgram.navigateTo({
                        url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/equity-detail&userId=1&token=1&userMembershipCardId=${v.userEquityCardId}`
                    })
                } else {
                    this.$router.push({
                        path: '/month-card/equity-detail',
                        query: {userMembershipCardId: v.userEquityCardId}
                    })
                }
            }
        },
        share(v) {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/give-equity-card&userId=1&token=1&recordId=${v.userEquityCardId}`
                })
            } else {
                this.$router.push({
                    path: '/give-equity-card',
                    query: {recordId: v.userEquityCardId}
                })
            }
        },
        // 点击立即激活
        async confirmActive(v) {
            this.cardType = v.type
            this.isCanGiving = v.isCanGiving
            if(this.appTypeStr !== 'mini' && this.cardType === 1){
                this.appVersion = await getAppVersion()
                if(compareVersion(this.appVersion, '2.70.10') < 0) {
                    this.$toast('需要更新APP新版本才能激活使用当前权益卡的权益优惠，请更新后再激活')
                    return false
                }
            }
            this.showActivate = true
            this.itemUserEquityCardId = v.userEquityCardId
        },
        activate() {
            const toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/user/equityCard/open`, {
                userId: this.userId,
                token: this.token,
                userEquityCardId: this.itemUserEquityCardId
            }).then((res) => {
                this.$toast.clear()
                this.getCardList()
                this.showActivate = false
            }).catch(() => {
                toast.close()
            })
        },
        newAppBack
    },
};
</script>

<style lang="less" scoped>
.page-rooter-fff {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    .page-content {
        flex-grow: 1;
        overflow: hidden;
    }

    .com_headerbar {
        flex-shrink: 0;
    }
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    min-height: 80vh;

    img {
        width: 160px;
        height: 160px;
    }

    div {
        margin-top: 12px;
        font-size: 24px;
        color: #999;
    }
}

.list {
    height: 100%;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    overflow: auto;

    .item + .item, .item-box + .item-box {
        margin-top: 32px;
    }
    .item-box{
        background-color: #fff;
        padding: 32px;
        width: 100%;
        border-radius: 16px;
        box-sizing: border-box;
        .item-top{
            width: 100%;
            display: flex;
            box-sizing: border-box;
            position: relative;
            background-color: #fff;
            border-bottom: 2px solid #F5F5F5;
            padding-bottom: 24px;
            .status {
                position: absolute;
                top: 0;
                right: 0;
                color: #FF6E00;
                font-size: 22px;
                &.gray{
                    color: #9aa1a9;
                }
            }
            .name {
                font-size: 24px;
                font-weight: bold;
                color: #242831;
                line-height: 38px;
                margin-bottom: 30px;
            }

            .use-tips {
                font-size: 20px;
                color: #3C454E;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }
        .item-bottom{
            padding: 32px 0 24px;
            .pay-msg{
                div{
                    img{
                        width: 40px;
                        height: 40px;
                        margin-right: 12px;
                    }
                    color: #242831;
                    font-size: 24px;
                }
                p{
                    color: #ED5C42;
                    font-size: 22px;
                }
            }
            .pay-tips{
                div{
                    line-height: 32px;
                    color: #3C454E;
                    font-size: 22px;
                    margin-top: 32px;
                }
                p{
                    font-size: 20px;
                    line-height: 28px;
                    color: #6C727A;
                    margin-top: 8px;
                }
            }
            .pay-explanation{
                div{
                    font-size: 24px;
                    line-height: 28px;
                    color: #242831;
                    margin-top: 40px;
                }
                p{
                    font-size: 20px;
                    line-height: 28px;
                    color: #6C727A;
                    margin-top: 16px;
                }
            }
        }
    }
    .item {
        &.expired {
            filter: grayscale(100%);
        }
        width: 100%;
        border-radius: 16px;
        padding: 32px 32px 24px;
        box-sizing: border-box;
        position: relative;
        background-color: #fff;
        .name {
            font-size: 24px;
            font-weight: bold;
            color: #242831;
            line-height: 38px;
            margin-bottom: 30px;
        }
        .use-tips {
            font-size: 20px;
            color: #3C454E;
            line-height: 20px;
            margin-bottom: 20px;
        }
        .status {
            position: absolute;
            top: 32px;
            right: 32px;
            color: #267DFF;
            font-size: 22px;
            &.gray{
                color: #9aa1a9;
            }
        }
        .refund{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 24px 0 8px;
            span{
                font-size: 20px;
                color: #6C727A;
                line-height: 28px;
            }
            div{
                width: 152px;
                height: 58px;
                background: #3C454E;
                border-radius: 4px;
                font-size: 24px;
                font-weight: 500;
                color: #FFFFFF;
                margin-left: 32px;
            }
        }
    }
}
.refund-box{
    width: 750px;
    background: #FFFFFF;
    border-radius: 12px;
    .refund-title{
        padding: 82px 0 32px;
        text-align: center;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        color: #242831;
    }
    .refund-content{
        padding: 0 32px;
        font-size: 28px;
        line-height: 44px;
        color: #9AA1A9;
        word-wrap: break-word;
        word-break: break-all;
    }
    .refund-info{
        margin-top: 56px;
        font-size: 28px;
        line-height: 28px;
        color: #242831;
        padding: 0 32px;
    }
    .refund-btn{
        margin-top: 156px;
        padding: 28px 32px;
        border-top: 1px solid #eee;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 96px;
            width: 332px;
            font-size: 28px;
            border-radius: 12px;
            border: 1px solid #eee;
            color: #242831;
            box-sizing: border-box;
            &:first-child{
                font-weight: 500;
                background-color: #FFDE00;
                border-color: #FFDE00;
            }
        }
    }
}
.popup-box{
    width: 630px;
    background: #FFFFFF;
    border-radius: 12px;
    .popup-title{
        padding: 82px 0 32px;
        text-align: center;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        color: #242831;
    }
    .popup-content{
        padding: 0 60px;
        text-align: center;
        font-size: 28px;
        line-height: 44px;
        color: #9AA1A9;
    }
    .popup-btn{
        border-top: 1px solid #EAEAEA;
        margin-top: 80px;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 108px;
            width: 50%;
            font-size: 32px;
            color: #9AA1A9;
            &:last-child{
                border-left: 1px solid #EAEAEA;
                color: #FF6E00;
            }
        }
    }
}
/deep/ .van-tab--active {
    font-weight: bold;
}

/deep/ .van-tabs {
    height: 100%;
}

/deep/ .van-hairline--top-bottom::after {
    border-top: none;
    border-color: #eee;
}

/deep/ .van-tabs__content, /deep/ .van-tab__pane {
    height: calc(100% - 44px);
}



.btn{
    padding-top: 8px;
    p{
        width: 144px;
        height: 56px;
        flex-shrink: 0;
        border-radius: 4px;
        opacity: 1;
        border: 1px solid #6C727A;
        box-sizing: border-box;
        color: #242831;
        font-size: 24px;
        margin-right: 24px;
        line-height: 24px;
        &.active{
            border-color: #FF6E00;
            color: #FFFFFF;
            background: #FF6E00;
        }
        &.gray{
            color: #9AA1A9;
            border-color: #DDDDDD;
            background: #DDDDDD;
        }
    }
    span{
        font-size: 20px;
        color: #6C727A;
    }
}
</style>
